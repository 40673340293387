.wrapper {
  width: 100%;
  /* height: 600px; */
}

.image {
  /* width: 100%; */
}

.parallaxItem {
  width: 100%;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
}

@media only screen and (max-width: 678px) {
  .parallaxItem {
    min-height: calc(100vh - 89px);
  }
}
